// Activate Premium Amplitude event handlers

$(document).ready(function() {
  // Due to the amount of places where this event can be fired,
  // we found more suitable to fire it from here
  $('[data-amplitude="click activate premium trigger"]').click(function() {
    var amplitudeProperties = this.dataset.amplitudeProperties;
    if (amplitudeProperties) {
      habTracker.track('click activate premium trigger', JSON.parse(amplitudeProperties));
    } else {
      habTracker.track('click activate premium trigger');
    }
  });
});
