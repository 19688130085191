(function(global) {
  'use strict';

  if (typeof global.habTracker === 'undefined') {
    global.habTracker = {};
  }
  if (typeof global.habClevertap === 'undefined') {
    global.habClevertap = {};
  };

  global.habTracker.track = function (eventName, eventProperties, callback) {
    window.habTracker.amplitude.track(eventName, eventProperties, callback);

    window.habClevertap.track(eventName, eventProperties);
  };
})(window);
