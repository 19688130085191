(function(w, $) {
  w.fbAsyncInit = function() {
    if (typeof FB !== 'undefined') {
      FB.init({
        appId: window.facebook_appid,
        status: false,
        xfbml: false,
        cookie: true,
      });
    }
  };

  if (typeof FB === 'undefined') {
    importScript('//connect.facebook.net/en_US/all.js', ['click']).catch(
      console.error
    );
  }

  function getSerializedUrlParameters(url_params) {
    var filtered = url_params.filter(function(object) {
      return object.value;
    });

    return $.param(filtered);
  }

  function getUrlWithSerializedParameters(url, url_params, user_type) {
    var url_params_serialized = getSerializedUrlParameters(url_params);
    var url_has_parameters = 0 <= url.indexOf('?');

    if (user_type) {
      url = getUrlWithUserTypeApplied(url, user_type, url_has_parameters);
    }

    if (url_params_serialized.length > 0) {
      var separator = url_has_parameters ? '&' : '?';
      url += separator + url_params_serialized;
    }

    return url;
  }

  function getUrlWithUserTypeApplied(url, user_type, url_has_parameters) {
    if (url_has_parameters) {
      var url_splitted = url.split('?');
      url = url_splitted[0] + '/' + user_type + '?' + url_splitted[1];
    } else {
      url += '/' + user_type;
    }

    return url;
  }

  function updateAmplitudeUserProperties() {
    localStorage.removeItem("queryParam")
    if (typeof w.habTracker !== 'undefined') {
      w.habTracker.amplitude.trackUserProperties();
    }
  }

  function clevertapUserProperties() {
    w.habClevertap.onUserLogin();
  }

  ('use strict');

  'Login' in w || (w.Login = {});
  (function() {
    var popped = false;

    /**
     * *Pops* up a bootbox modal asking for user to login.
     *
     * On success, a redirection to `options.login_success_url` will be performed
     * (defaults to current `document.location`).
     *
     * Popup title can be customized by setting the `title` option.
     */
    w.Login.pop = function(options) {
      if (popped) {
        return;
      }

      var brand_name = 'habitissimo';

      if (typeof window.get_brand_name === 'function') {
        brand_name = get_brand_name();
      }

      var signInTitle =
        '<div data-title="signin"> <h1>' +
        __('Iniciar sesión') +
        '</h1>' +
        '<small> <strong>' +
        __('¿No estás registrado en {brand_name}?', {
          brand_name: brand_name,
        }) +
        '</strong>' +
        '<div class="p-t-xs"> <a class="brand-color" data-action="show-signup-popup">' +
        __('Crear cuenta') +
        '</a> </div> </small> </div>';
      var signUpTitle =
        '<div data-title="signup"> <h1>' + __('Crear cuenta') + '</h1> </div>';
      options = $.extend(
        {
          back: false,
          title: signUpTitle + signInTitle,
          onsuccess: null,
          beforeopen: null,
          default_action: 'register',
          container: null,
          header: null,
          template_url: Hab.config.ajax_signin_url,
          active_selector_class: 'hidden',
        },
        options
      );

      var url = options.template_url;

      var url_params = [
        {
          name: 'back',
          value: options.back ? encodeURIComponent(options.back) : false,
        },
        {
          name: 'source_page',
          value: options.source_page,
        },
        {
          name: 'source_action',
          value: options.source_action,
        },
      ];

      $.ajax({
        type: 'GET',
        url: getUrlWithSerializedParameters(url, url_params, options.user_type),
        success: function(html, st, xhr) {
          if (!options.onsuccess) {
            options.onsuccess = function() {
              document.location.href = options.back;
            };
          }

          if (xhr.status == 204) {
            if (typeof options.onsuccess === 'function') {
              setTimeout(function() {
                options.onsuccess.call(this);
              }, 0);
            }
            return;
          }

          var $dialog;

          if (!options.container) {
            $dialog = bootbox.dialog({
              width: 800,
              message: html,
              className: 'login-modal',
              title: options.title,
              onEscape: function() {
                return true;
              },
              show: false,
            });

            $dialog.attr('id', 'login-modal');
          } else {
            $dialog = $(options.container);
            $dialog.html(html);
            $dialog.addClass('login-modal');
          }

          if (options.header) {
            $dialog
              .closest('.bootbox')
              .find('.modal-body')
              .before(
                "<div class='modal-header'><h4 class='modal-title'>" +
                  options.title +
                  '</h4></div>'
              );
            $dialog
              .closest('.bootbox')
              .find('.modal-header')
              .prepend($('.bootbox-close-button'));
          }

          $('[data-action="sign-in"]').bind('click', trackCustomerSignIn);
          $('[data-action="show-signup-popup"]').bind('click', function() {
            showSignUp();
            window.habTracker.track('click register user');
          });
          $('[data-action="show-signin-popup"]').bind('click', function() {
            showSignIn();
            window.habTracker.track('click login user');
          });
          $('[data-action="user-forgot-password"]').bind('click', function() {
            window.habTracker.track('click unknown password');
          });
          $('.social-login a').bind('click', function() {
            sendSocialAmplitudeEvents($(this));
          });

          showDefaultAction();

          if (options.beforeopen) {
            options.beforeopen.call(this);
          }

          if (!options.container) {
            $dialog.modal('show');
            popped = true;

            $dialog.on('hidden.bs.modal', function() {
              popped = false;
            });
          } else {
            $dialog.show();
          }

          $dialog.find('#login_customer_form_pop').on('submit', function(e) {
            var $form = $(this).closest('form');

            $form.find('[type=submit]').attr('disabled', 'disabled');

            var login_url = Hab.config.api_root + '/user/signin';

            var login_url_params = [
              {
                name: 'source_page',
                value: options.source_page,
              },
              {
                name: 'source_action',
                value: options.source_action,
              },
            ];

            $.ajax({
              type: 'POST',
              url: getUrlWithSerializedParameters(
                login_url,
                login_url_params,
                options.user_type
              ),
              data: $form.serialize(),
              success: function() {
                updateAmplitudeUserProperties();

                document.cookie = 'onUserLoginClevertap=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/; secure';

                window.habTracker.track('login user success', {
                  'Last_Date_Hour': new Date()
                });

                if (document.cookie.match(/^(.*;)?\s*onUserLoginClevertap\s*=\s*[^;]+(.*)?$/)) {
                  clevertapUserProperties();
                }

                if (typeof options.onsuccess === 'function') {
                  setTimeout(function() {
                    options.onsuccess.call(this);
                  }, 0);
                }
                if (!options.container) {
                  $dialog.modal('hide');
                }
              },
              error: function(xhr) {
                var error_message = $form.find('#generic_signin_error');
                try {
                  $form
                    .find('.form-group')
                    .removeClass('has-error')
                    .find('.error')
                    .remove();

                  var response = $.parseJSON(xhr.responseText);
                  if (response.errors) {
                    var e, $input;

                    for (e in response.errors) {
                      $input = $form.find('[name=' + e + ']');

                      if ($input.length > 0) {
                        var $field = $input.closest('.form-group');
                        $field.addClass('has-error');
                        $('<small class="help-block error"/>')
                          .html(response.errors[e])
                          .hide()
                          .appendTo($field)
                          .fadeIn('fast');

                        break;
                      }
                    }
                  }
                  if (response.global_error) {
                    error_message.removeClass('hidden').fadeIn('fast');
                  }
                } catch (e) {}

                $form.find('[type=submit]').removeAttr('disabled');
              },
            });

            e.preventDefault();
          });

          $dialog.find('#register-customer-form-pop').on('submit', function(e) {
            var $form = $(this).closest('form');

            $form.find('[type=submit]').attr('disabled', 'disabled');

            $.ajax({
              type: 'POST',
              url:
                Hab.config.api_root +
                '/user/signup?source_page=' +
                options.source_page +
                '&source_action=' +
                options.source_action,
              data: $form.serialize(),
              success: function() {
                updateAmplitudeUserProperties();
                window.habTracker.track('register user success');

                if (typeof options.onsuccess === 'function') {
                  setTimeout(function() {
                    options.onsuccess.call(this);
                  }, 0);
                }
                if (!options.container) {
                  $dialog.modal('hide');
                }
              },
              error: function(xhr) {
                try {
                  $form
                    .find('.form-group')
                    .removeClass('has-error')
                    .find('.error')
                    .remove();

                  var errors = $.parseJSON(xhr.responseText);

                  if (errors) {
                    var e, $input;

                    for (e in errors) {
                      $input = $form.find('[id=' + e + ']');

                      if ($input.length > 0) {
                        var $field = $input.closest('.form-group');
                        $field.addClass('has-error');
                        $('<small class="help-block error"/>')
                          .html(errors[e])
                          .hide()
                          .appendTo($field)
                          .fadeIn('fast');

                        break;
                      }
                    }
                  }
                } catch (e) {}

                $form.find('[type=submit]').removeAttr('disabled');
              },
            });

            e.preventDefault();
          });

          function trackCustomerSignIn() {
            if (options.source_action === 'qna_add_question') {
              window.habTracker.track('View step homeowner register');
            }
          }

          function showSignUp() {
            window.habTracker.track('view register modal');

            $('[data-form="signin-popup"]')
              .hide()
              .addClass('hidden');
            $('[data-block="signin-popup"]')
              .show()
              .removeClass('hidden');
            $('[data-form="signup-popup"]')
              .show()
              .removeClass('hidden');

            $('[data-title="signin"]')
              .hide()
              .addClass('hidden');
            $('[data-title="signup"]')
              .show()
              .removeClass('hidden');

            $('#signin-popup .facebook-text').text(
              __('Regístrate con Facebook')
            );
            $('#signin-popup .google-text').text(__('Regístrate con Google'));
            $('#signin-popup .apple-text').text(__('Regístrate con Apple'));
            $('#signin-popup .registration-text').removeClass('hidden');
            $('#signin-popup .login-text').addClass('hidden');
            if (options.source_action === 'qna_add_question') {
              window.habTracker.track('View step register');
            }
          }

          function showSignIn() {
            window.habTracker.track('view login modal');

            $('[data-form="signup-popup"]')
              .hide()
              .addClass('hidden');
            $('[data-block="signin-popup"]')
              .hide()
              .addClass('hidden');
            $('[data-form="signin-popup"]')
              .show()
              .removeClass('hidden');

            $('[data-title="signup"]')
              .show()
              .hide()
              .addClass('hidden');
            $('[data-title="signin"]')
              .show()
              .removeClass('hidden');

            $('[data-block="social-buttons"] .facebook-text').text(
              __('Entra con Facebook')
            );
            $('[data-block="social-buttons"] .google-text').text(
              __('Entra con Google')
            );
            $('[data-block="social-buttons"] .apple-text').text(
              __('Entra con Apple')
            );
            $('#signin-popup .registration-text').addClass('hidden');
            $('#signin-popup .login-text').removeClass('hidden');
          }

          function showDefaultAction() {
            if (options.default_action === 'login') {
              showSignIn();
            } else {
              showSignUp();
            }
          }

          function sendSocialAmplitudeEvents(element) {
            var socialType = '';
            switch (element.attr('id')) {
              case 'fb-login':
                socialType = 'facebook';
                break;
              case 'google-login':
                socialType = 'google';
                break;
              case 'apple-id-login':
                socialType = 'apple';
                break;
            }
            var eventProperties = { 'type of social': socialType };
            if ($('[data-form="signin-popup"]').is(':visible')) {
              window.habTracker.track('click social login', eventProperties);
            } else {
              window.habTracker.track('click social register', eventProperties);
            }
          }
        },
        error: function() {
          if (options.href !== null) {
            document.location.href = Hab.config.signin_url;
          }
        },
      });
    };

    w.Login.container = function(options) {
      var brand_name = 'habitissimo';

      if (typeof window.get_brand_name === 'function') {
        brand_name = get_brand_name();
      }

      options = $.extend(
        {
          back: document.location.href,
          title:
            '<h1>' +
            __('¡Bienvenido a {brand_name}!', {
              brand_name: brand_name,
            }) +
            '</h1>' +
            '<small>' +
            __(
              'Regístrate y podrás inspirarte, resolver tus dudas, encontrar profesionales para tu hogar...'
            ) +
            '</small>',
          onsuccess: null,
          default_action: 'register',
          container: '',
          template_url: Hab.config.ajax_signin_url,
          active_selector_class: 'active',
        },
        options
      );

      return w.Login.pop(options);
    };
  })();

  w.Login.autoPop = function() {
    // Don't show if there's any other BS modal around or the `login_prompt`
    // cookie
    if ($('.modal.in').length) {
      return;
    }

    if ($.cookie('login_prompt')) {
      return;
    }

    var options = {
      source_page: 'pop_up_register',
      source_action: 'pop_up_register',
    };

    w.Login.pop.apply(this, [options]);

    $.cookie('login_prompt', 'prompted', {
      path: '/',
      domain: window.cookie_domain,
      secure: true,
    });
  };

  /**
   * Listens for events trigged by different components in the web.
   * When an event has happened n times the login popup is showed.
   *
   * Also sets a timeout to show the popup.
   */
  w.Login.listen = function() {
    var scrollCount = 0,
      slideCount = 0;

    $(window).on('moebius.pageLoaded', function() {
      scrollCount++;
      if (scrollCount > 2) {
        Login.autoPop();
      }
    });

    $(window).on('slideshow.pageLoaded', function() {
      slideCount++;
      if (slideCount > 12) {
        Login.autoPop();
      }
    });

    if (!$.cookie('login_prompt')) {
      setTimeout(function() {
        Login.autoPop();
      }, 30 * 1000);
    }
  };
})(window, jQuery);
