import './public-path';
import './utils/importScript';
import './polyfills';
import './trackers/track_errors';
import './trackers/track_events';
import './trackers/amplitude/amplitude';
import './trackers/clevertap/clevertap';
import './trackers/habTracker';
import './trackers/amplitude/business_register';
import './trackers/amplitude/activate_premium';
import './trackers/amplitude/homeowners_directory';
import 'bower/bootstrap/dist/js/bootstrap';
import 'bower/trunk8/trunk8';
import 'bower/jquery.cookie/jquery.cookie';
import 'bower/jquery.validation/jquery.validate';
import 'bower/jquery.validation/additional-methods';
import './utils/nano';
import 'bower/jQuery-Mask-Plugin/dist/jquery.mask.min';
import 'bower/jquery-form-example/jquery.example.min';
import './affix';
import './album';
import './social';
import './slideshow';
import './moebius';
import './helpers';
import './login';
import './quotation/rfq';
import '../../js/areas/i18n';
import '../../js/areas/gmaps';
import './components/lazy/lazy';
import './views/business/quotation/manager';
import './components/chat/chat';
import './karuselo';
import './content-placeholder';
import 'bower/jquery.idle/jquery.idle.min';
import 'bower/jquery-hoverintent/jquery.hoverIntent';
import './range-input';
import './deeplinker';
import './location_helper';
import './form_feedback';
import './searcher/category';
import './searcher/location';
import './searcher/tag';
import './open-in-modal';
import './jquery.textareaVerticalResize';
import '../../../Habitissimo/Utils/Web/Src/Component/components';
import './components/navbar/secondary_navbar';
import './components/navbar/navbar';
import './components/navbar/navbar_customer';
import './components/navbar/navbar_notifications';
import './components/navbar/navbar_menu_toggle';
import './user_sign_in';
import './footer';
import './on_load';
import './overlay-cta';
import { isInView } from './utils/isInView';
import LazyHammer from './libs/Hammer';

window.Bloodhound = require('bower/corejs-typeahead/dist/typeahead.bundle');
window.bootbox = require('bootbox');
window.CountUp = require('bower/countUp.js/dist/countUp');
window.isInView = isInView;
window.LazyHammer = LazyHammer;

/**
 * Lite of hacks to make async load
 * for some plugins
 */
import './utils/fullScreenVideo/utils';
window.Clipboard = function(target, onSuccess, onError) {
  import('clipboard').then(({ default: Clipboard }) => {
    const clip = new Clipboard(target);
    if (typeof onSuccess === 'function') {
      clip.on('success', onSuccess);
    }

    if (typeof onError === 'function') {
      clip.on('error', onSuccess);
    }
  });
};

$.fn.sharrre = function(...args) {
  import('bower/sharrre/jquery.sharrre').then(() => {
    $.fn.sharrre.call(this, ...args);
  });
};

const fancyBoxLoad = function(...args) {
  import('./components/fancybox/fancybox-plugin').then(() => {
    const isElement = this && this.length > 0 && typeof this !== 'function';
    if (isElement) {
      return this.each((_, el) => {
        return $(el).fancybox(...args);
      });
    }
    $.fancybox.call(this, ...args);
  });
};

$.fancybox = fancyBoxLoad;
$.fn.fancybox = fancyBoxLoad;
