window.rfqLanding = {
  bind(selector = '[data-type="wc-cta"]:not(.disabled)') {
    $(selector).off('click', handlerClick);
    $(selector).on('click', handlerClick);
  },
};

function handlerClick(event) {
  event.preventDefault();
  const target = $(this).attr("data-target");
  const href = $(this).attr("data-href");

  if (target && target === "_blank") {
    openNewTab(href);
  } else {
    go(href);
  }
  return false;
}

$(() => {
  rfqLanding.bind();
});
