$(document).ready(function() {
  $('.rotate').click(function() {
    var $icon = $(this).find('.icon-rotate');
    var toOpenIcon = 'icon-plus-circle-o';
    var toCloseIcon = 'icon-minus-circle-o';
    var $collapsable = $(this).find('.collapse');
    $collapsable.collapse('toggle');
    $icon.toggleClass('opened');
    $icon.toggleClass(toCloseIcon);
    $icon.toggleClass(toOpenIcon);
  });

  $('.rotate-alternative').click(function () {
    var $icon = $(this).find('.icon-rotate');
    var toOpenIcon = 'icon-chevron-down-o';
    var toCloseIcon = 'icon-chevron-up-o';
    var $collapsable = $(this).find('.collapse');
    $collapsable.collapse('toggle');
    $icon.toggleClass('opened');
    $icon.toggleClass(toCloseIcon);
    $icon.toggleClass(toOpenIcon);
  });

  $('.panel-heading-register-first-alternative').click(function () {
    var $icon = $(this).find('.icon-rotate');
    var toOpenIcon = 'icon-chevron-down-o';
    var toCloseIcon = 'icon-chevron-up-o';
    var $collapsable = $('.rotate-register-first-alternative').find('.collapse');
    $collapsable.collapse('toggle');
    $icon.toggleClass('opened');
    $icon.toggleClass(toCloseIcon);
    $icon.toggleClass(toOpenIcon);

    dataLayer.push({
      event: "step_pii_business_register"
    });
    habTracker.track('view step contact details');
  });

  $('.panel-heading-register-second-alternative').click(function () {
    var $icon = $(this).find('.icon-rotate');
    var toOpenIcon = 'icon-chevron-down-o';
    var toCloseIcon = 'icon-chevron-up-o';
    var $collapsable = $('.rotate-register-second-alternative').find('.collapse');
    $collapsable.collapse('toggle');
    $icon.toggleClass('opened');
    $icon.toggleClass(toCloseIcon);
    $icon.toggleClass(toOpenIcon);
  });

  $('.panel-heading-register-categories').click(function () {
    var $icon = $(this).find('.icon-rotate');
    var toOpenIcon = 'icon-chevron-down-o';
    var toCloseIcon = 'icon-chevron-up-o';
    var $collapsable = $('.rotate-register-categories').find('.collapse-categories');
    $collapsable.collapse('toggle');
    $icon.toggleClass('opened');
    $icon.toggleClass(toCloseIcon);
    $icon.toggleClass(toOpenIcon);
  });

  $('.panel-heading-register-size').click(function () {
    var $icon = $(this).find('.icon-rotate');
    var toOpenIcon = 'icon-chevron-down-o';
    var toCloseIcon = 'icon-chevron-up-o';
    var $collapsable = $('.rotate-register-size').find('.collapse-size');
    $collapsable.collapse('toggle');
    $icon.toggleClass('opened');
    $icon.toggleClass(toCloseIcon);
    $icon.toggleClass(toOpenIcon);
  });

  $('.btn[data-load-on-click]').on('click', function() {
    $(this).addClass('btn-spinner disabled');
  });

  var hasWhitelist =
    $.fn.tooltip &&
    $.fn.tooltip.Constructor &&
    $.fn.tooltip.Constructor.DEFAULTS &&
    $.fn.tooltip.Constructor.DEFAULTS.whiteList['*'];

  if (hasWhitelist) {
    $.fn.tooltip.Constructor.DEFAULTS.whiteList['*'].push(/^data-[\w-]+$/i);
  }

  $.ajax('/p/api/notification').done(function(data) {
    if (data.status === 'success') {
      data.message.forEach(function(notice) {
        Utilities.showNoticeWithoutDispatcher({
          id: notice.id,
          type: notice.type,
          text: notice.body,
        });
      });
    }
  });
});
