jQuery(function($) {
  var $body = $(document.body),
    $notifications = $('.navbar-notifications');

  $notifications.on('click', 'li.navbar-notification > a', function(ev) {
    ev.preventDefault();

    var $this = $(this),
      $li = $this.closest('li'),
      $target = $($this.attr('href'));

    $('li.navbar-notification')
      .not($li)
      .removeClass('active');

    $li.toggleClass('active');

    if ($li.hasClass('active')) {
      var onMouseDown = function(ev) {
        if ($target[0] !== ev.target && !$.contains($target[0], ev.target)) {
          $li.removeClass('active');
          $body.off('mousedown', onMouseDown);
        }
      };

      setTimeout(function() {
        $body.on('mousedown', onMouseDown);
      }, 0);
    }
  });

  // eslint-disable-next-line
  $notifications.on('touchstart', function(ev) {
    var onBodyClick = function(ev) {
      if (!$(ev.target).closest('.navbar-notifications').length) {
        $('.navbar-notification.active').removeClass('active');
        $(document.body).off('touchstart', onBodyClick);
      }
    };

    $(document.body).on('touchstart', onBodyClick);
  });
});
