(function (global) {
    'use strict';

    if (typeof global.habClevertap === 'undefined') {
        global.habClevertap = {};
    }

    global.habClevertap = {
        
        track: function (eventName, eventProperties) {

            $.ajax({
                url: '/p/api/user/authenticated',
                method: 'GET',
                type: 'JSON',
                success: function (data) {
                    if (data.message.isAuthenticated) {
                        eventProperties
                            ? clevertap.event.push(eventName, eventProperties)
                            : clevertap.event.push(eventName);

                        document.cookie = 'onUserLoginClevertap=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/; secure';
                    }
                }
            });
        },

        onUserLogin: function () {
            $.ajax({
                url: '/p/api/clevertap/user/properties',
                method: 'GET',
                type: 'JSON',
                success: function (userProperties) {
                    if (userProperties) {
                        clevertap.onUserLogin.push(userProperties);
                        clevertap.profile.push(userProperties);
                    };
                },
            });
        },

        profileUpdate: function (properties) {
            clevertap.profile.push(properties);
        },
    };

    var isAuthenticated = document.cookie.split(";").some( function (item) { 
        return item.trim().includes("onUserLoginClevertap")});

    if (isAuthenticated) {
        window.habClevertap.onUserLogin();
    }

})(window);
